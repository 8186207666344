<template>
    <div class="absolute p-6 w-full flex justify-end ">
        <DarkMode divWidth="w-10" />
    </div>
    <div class="flex items-center min-h-screen">
        <div class="w-96 mx-auto bg-white border border-gray-200 rounded-lg shadow p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
            <ErrorMsg v-if="error.message" :res="error" addtl="" />
            <Loading v-if="loading" />
            <div v-if="error !== ''" class="bg-red-300 text-red-900 rounded-lg p-4 mb-12 font-semibold max-w-4xl m-auto">
                {{ error }}
            </div>

            <h4 class="text-gray-900 dark:text-white text-xl mb-10 border-current">Find Your Travel App</h4>

            <p class="font-normal">Please enter your {{ companyName }} booking reference. </p>
            <Form @submit="findItinerary" class="flex flex-col mt-6 space-y-7">
                <div>
                    <label for="ref" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ $t('reference') }}:</label>
                    <Field
                        name="ref"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        type="text"
                        placeholder="Booking Reference"
                        v-model="newRef"
                        rules="required"
                    />
                    <ErrorMessage name="ref" class="text-sm text-red-300" />
                </div>
                <submit-button buttonText="Find Itinerary" />
            </Form>
        </div>
    </div>

</template>

<script setup>
import axios from 'axios';
import { ref, onMounted, watch } from "vue"
import { Field, Form, ErrorMessage } from 'vee-validate'
import Loading from '../micro/Loading.vue'
import SubmitButton from '../micro/SubmitButton.vue'
import DarkMode from '../common/DarkMode.vue'
import { useRouter } from "vue-router";
import { useEnvSettingsStore } from "../admin/stores/envSettings";
import ErrorMsg from "@/components/micro/ErrorMsg.vue";

const envStore = useEnvSettingsStore()
const router = useRouter()
const companyName = ref('')
const loading = ref(false)
const error = ref({
    message: ''
})
const noId = ref(false)
const newRef = ref('')

onMounted(async() => {
    await envStore.getSettings()
})

watch(() => envStore.env, (newEnv) => {
    if(newEnv){
        companyName.value = envStore.env.general.company_name
    }
});

const findItinerary = function(){
    console.log(newRef.value)
    axios
    .get('/api/find-itinerary/' + newRef.value)
    .then(({data})=>{
        console.log(data)
        router.push({path: '/travel-app/' + data})
    })
    .catch(err => {
        error.value.message = err.response.data.message
        loading.value = false;
    })
}
</script>
